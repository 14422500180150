<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        [{{ patientData && patientData.nameFull }}] Patient Survey
      </v-card-title>

      <SurveyForm ref="refSurveyForm" :surveyData="surveyData" />

      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" depressed large @click="saveData">
          {{ surveyData && surveyData.ipk > 0 ? "UPDATE" : "SAVE" }}
        </v-btn>
        <v-btn color="danger" depressed large v-if="surveyData && surveyData.ipk > 0" @click="deleteData">DELETE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import SurveyForm from "./form/SurveyForm.vue";
const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    BtnBox,
    SurveyForm,
  },
  data() {
    return {
      visible: false,
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      patientData: null,
      surveyData: modelService.patientSurveyModel(),
      // reqData: modelService.reqDataModel(),
      // kData: modelService.hospitalModel(),
    };
  },
  methods: {
    Open(patientData, surveyData) {
      console.log("patientData", patientData);
      console.log("surveyData", surveyData);
      this.visible = true;
      this.patientData = patientData;

      if (surveyData === undefined) {
        this.surveyData = modelService.patientSurveyModel();
      } else {
        // 수정시
        if (surveyData.ipk > 0) {
          surveyData.pageTitle = surveyData.question;
          surveyData.answerNum = surveyData.answers.length;
          surveyData.answerList = this.$helper.makeAnswerArray(surveyData.answerNum, surveyData);
        }
        this.surveyData = surveyData;
        console.log('surveyData', surveyData);
      }

      setTimeout(() => {
        this.$refs.refSurveyForm.$refs.refQuestion.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    // 수정/저장/삭제
    async saveData() {
      // 필수
      const $h = this.$helper;
      const D = this.surveyData;
      let RF = this.$refs;
      if (!RF.refSurveyForm.$refs.form.validate()) return;

      let msg = `Target Date ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.targetDate, null, msg, false)) return;

      msg = `Send Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.sendType, null, msg, false)) return;

      D.answers = [];
      if (D.surveyGbn === 'M') {
        for (let i = 0; i < D.answerNum; i++) {
          D.answers.push(D.answerList[i].value);
        }
      }

      // 설문답변내용 검사
      let isAnswerValueOk = true;
      for (let i = 0; i < D.answerNum; i++) {
        if (D.answerList[i].value === '') {
          isAnswerValueOk = false;
          break;
        }
      }

      if (!isAnswerValueOk) {
        msg = `Answer ${this.requiredMsg}`;
        this.$helper.showTostMessage('', msg, "error");
        return;
      }

      console.log('surveyData', D);
      // return;
      this.saveProcess();
    },
    async deleteData() {
      if (!confirm('Delete?')) return;
      this.surveyData.useYN = 'N';
      this.surveyData.answers = [];
      this.saveProcess();
    },
    async saveProcess() {

      const $h = this.$helper;
      this.surveyData.targetDate = $h.changeDateToString(this.surveyData.targetDate);
      this.surveyData.user_id = this.patientData.user_id;
      this.surveyData.endpoint = 'patientSurvey';
      await this.saveDataQuery(this.surveyData).then((res) => {
        if (res === undefined) return;
        let messageLabel = this.surveyData.ipk === 0 ? 'S' : 'U';
        if (this.surveyData.useYN === 'N') messageLabel = 'D';
        let messageType = 'success';
        $h.showTostMessage('', $h.commonMessage(messageLabel), messageType);
        this.visible = false;
        this.$emit('completeSaveData', this.surveyData);
      });
    },
    async answerNumUpdateSelect(e) {
      this.reqData.answerNum = e;
      this.reqData.answerList = [];
      // 설문내용 처리
      this.reqData.answerList = this.$helper.makeAnswerArray(e, this.reqData);
    },
    onChangeDp(checked) {
      this.reqData.isDp = checked ? 'Y' : 'N';
    },
    calendarEvent(e) {
      if (e === '' || e === null) return;
      // 날짜 비교
      this.reqData.targetDate = this.$moment(e._d).format('YYYY-MM-DD');
    },
    // 창닫을때
    afterClose() {
      this.$emit('completeSaveData', this.reqData);
    },
    ...mapActions(['saveDataQuery']),
    // activeClinic(option) {
    //   this.clickData.activated = option;
    //   this.saveAction(this.clickData);
    // },
    // /** 데이터읽기  */
    // async getHospitalData() {
    //   this.reqData.endpoint = "hospitalOne";
    //   await this.getDataQuery(this.reqData).then((res) => {
    //     if (res === undefined) return;
    //     let resData = res.data;
    //     this.clickData = resData;
    //     console.log("", resData);
    //   });
    // },
    // /** 저장/수정/삭제  */
    // async saveClinic() {
    //   if (!this.$refs.refClinicAddForm.$refs.form.validate()) return;

    //   console.log("", this.clickData);
    //   const saveLbl = this.clickData.hospital_id > 0 ? "Update" : "Register";
    //   if (!confirm(`Would you like to ${saveLbl}?`)) return;
    //   this.saveAction(this.clickData);
    // },
    // async saveAction(data) {
    //   const $h = this.$helper;
    //   data.endpoint = "hospital";
    //   await this.saveDataQuery(data).then((res) => {
    //     if (res === undefined) return;
    //     $h.showTostMessage("", $h.commonMessage("S"), "success");
    //     this.visible = false;
    //     this.$emit("complete", res);
    //   });
    // },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
</style>