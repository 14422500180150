<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'"
        :dark="$vuetify.theme.dark"
        class=""
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            {{ patientData && patientData.nameFull }} Daily Survey
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="mainStyle">

      <TopTable
        btnLabel="NEW"
        :btnIsShow="true"
        :IsSwitchShow="false"
        :xlsxBtnIsShow="false"
        :data="surveyData"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
      />

      <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :tableKey="'rowSeq'"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="surveyTableColumns"
        :inputData="surveyData"
        @clickRow="tableClickRow"
      />
    </div>

    <!-- add / edit -->
    <DialogSurveyEditor ref="refEditorPage" @completeSaveData="editorCompleteSaveData" /> 
  </vue-perfect-scrollbar>
  <!-- <a-drawer
    :title="userData && userData.name + `(Patient) Survey Management`"
    placement="right"
    width="60%"
    :closable="true"
    :visible="visible"
    @close="onClose"
  >
    <div class="container single">
      <div class="table-top-ui">
        <TopTotal
          :data="List"
          btnLabel="New"
          :xlsxBtnIsShow="false"
          @clickEditorBtn="clickEditorBtn"
          @clickReload="clickReload"
        />
      </div>

      <Table :data="List" :columns="inquiryTable" @clickRow="tableClickRow" />

      <SurveyEditor ref="editorPage" @completeSaveData="editorCompleteSaveData" /> 
    </div>
  </a-drawer> -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogSurveyEditor from "@/components/commonV2/Dialog/DialogSurveyEditor.vue";
// import SearchDate from '@/components/common/v2.0/SearchDate.vue';
// import topUI from '@/components/common/v2.0/SearchTopUIComp.vue';
// import TopTotal from '@/components/common/v2.0/TopTotal.vue';
// import Table from '@/components/common/v2.0/Table.vue';
// import SelectBox from '@/components/common/v2.0/SelectBox.vue';
// import SurveyEditor from './SurveyEditor.vue';
const modelService = require('@/utils/dataModel');
const formatService = require('@/utils/format.js');

export default {
  components: {
    TopTable,
    DataTable,
    DialogSurveyEditor,
    // topUI,
    // Table,
    // TopTotal,
    // SelectBox,
    // SearchDate,
    // SurveyEditor,
  },
  data() {
    return {
      visible: false,
      loading: false,
      loadingText: "Loading list...",
      patientData: {},
      surveyData: [],
      surveyTableColumns: formatService.surveyTableColumns(),
      searchVal: '',
      // xlsxData: [],
      selectedBtn: 'month1',
      propsDate: [this.$moment().subtract(30, 'days'), this.$moment()],
      reqData: modelService.patientSurveyModel(),
    };
  },
  methods: {
    _showDrawer(patientData) {
      console.log(patientData);
      this.visible = true;

      // 최종바인딩.
      this.patientData = patientData;
      this.getData();
    },
    // 데이터읽기
    async getData() {
      this.surveyData = [];
      this.patientData.endpoint = 'patientSurvey';
      await this.getDataQuery(this.patientData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            answerList: [], // 데이터동기화
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate),
            dpDateLabel: this.$helper.changeDateToForUpdateDp(item.regDate, item.updDate),
            targetDateLabel: this.$helper.changeDateToStringForDp(item.targetDate)
          }));
        }
        this.surveyData = res;
      });
    },
    clickReload() {
      this.getData();
    },
    clickNewBtn() {
      // 초기화
      // this.reqData = modelService.patientSurveyModel();
      // this.patientData.user_id = this.patientData.user_id;
      this.$refs.refEditorPage.Open(this.patientData);
    },
    tableClickRow(e) {
      this.$refs.refEditorPage.Open(this.patientData, e);
    },
    // 저장/수정/삭제 후 처리
    editorCompleteSaveData(e) {
      console.log('complete');
      this.getData();
    },
    onClose() {
      this.visible = false;
    },
    ...mapActions(['getDataQuery']),
  },
};
</script>