<template>
  <v-card-text class="answer-list">
    <v-row class="list-header">
      <v-col cols="10">Answer List</v-col>
      <v-col class="answer-label" cols="2" v-if="disabled">Answer</v-col>
    </v-row>
    <v-row class="list-body">
      <v-col>
        <!-- 객관식답변 -->
        <v-row v-for="item of answerList" :key="item.id">
          <v-text-field ref="`answer`{{item.id}}" v-model="item.value" 
            :disabled="disabled" />
          <!-- 응답 수, 응답 비율 포시 필요 -->
          <!-- {{ item.isAnswer }} -->
          <v-icon v-if="ipk > 0 && item.isAnswer">mdi-check-bold</v-icon>
        </v-row>
        <!-- 주관식답변(없음) -->
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
export default {
  props: {
    answerList: {
      default: null,
      type: [Object, Array],
    },
    ipk: {
      default: 0,
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style lang="scss">
.list-header {
  height: 50px;
  padding: 15px;
  background: rgb(206, 228, 234);
}
.answer-label {
  text-align: right;
}
.list-body {
  margin-top: 25px !important;
}
</style>