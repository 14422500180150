<template>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-text-field
              ref="refQuestion"
              label="* Question"
              v-model="surveyData.question"
              :rules="[filedRules.required, filedRules.counter]"
              :disabled="surveyData && surveyData.answerResults && surveyData.answerResults.length > 0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DatePickerWithInputText 
              :value="$helper.changeDateToString(surveyData.targetDate)" 
              :propsDisabled="surveyData && surveyData.answerResults && surveyData.answerResults.length > 0"
              @onChangeDate="onChangeDateDatePicker" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <RadioComps
              :items="sendTypeItems"
              :prosSelected="sendTypeValue"
              :prosDisabled="surveyData && surveyData.answerResults && surveyData.answerResults.length > 0"
              @changeType="sendTypeChangeType"
            />
          </v-col>
        </v-row>
        <v-row class="regDateRow" v-if="surveyData.ipk > 0">
          <v-col>
            Last Update : {{ surveyData.dpDateLabel }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SelectBox
              label="Answer Counter"
              selectStyleWidth="width: 100%"
              :value="surveyData.answers.length"
              :items="answerNumItems"
              :multiple="false"
              :disabled="surveyData && surveyData.answerResults && surveyData.answerResults.length > 0"
              @changeSelect="answerNumUpdateSelect"
            />
          </v-col>
        </v-row>
        <!-- 설문응답내용 -->
        <SurveyResult 
          :answerList="surveyData.answerList" 
          :ipk="surveyData.ipk"
          :disabled="surveyData && surveyData.answerResults && surveyData.answerResults.length > 0" />
      </v-form>
    </v-card-text>
</template>
<script>
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import SurveyResult from "./SurveyResult.vue";
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");

export default {
  components: {
    DatePickerWithInputText,
    RadioComps,
    SelectBox,
    SurveyResult
  },
  props: {
    surveyData: {
      type: [Object, Array],
    },
  },
  watch: {
    surveyData(val) {
      this.sendTypeValue = val.sendType
    }
  },
  data() {
    return {
      sendTypeValue: this.surveyData.sendType,
      sendTypeItems: formatService.sendTypeItems(),
      answerNumItems: formatService.answerNumItems(),
      filedRules: rulesService.filedRules()
    }
  },
  methods: {
    onChangeDateDatePicker(e) {
      console.log('e', e);
      if (e === '' || e === null) return;
      // 날짜 비교
      this.surveyData.targetDate = e;  //this.$moment(e._d).format('YYYY-MM-DD');
    },
    sendTypeChangeType(e) {
      console.log('e', e);
      this.surveyData.sendType = e;
    },
    async answerNumUpdateSelect(e) {
      this.surveyData.answerNum = e;
      this.surveyData.answerList = [];
      // 설문내용 처리
      this.surveyData.answerList = this.$helper.makeAnswerArray(e, this.surveyData);
    },
  },
}
</script>
<style lang="scss">
  .regDateRow {
    height: 68px !important;
    color: rgb(247, 70, 70);
  }
</style>